<template>
  <div :class="headerbg" class="app-header header-shadow">
    <div v-if="isMain" class="login-main-top anivertilogo" @click="routeMain">경농 EHS</div>
    <div v-else class="logo-src-label slidelogo">
      {{menuTitle}}
    </div>
    <div class="app-header__content">
      <div class="app-header-left">
        <div class="header__pane ml-auto menucollapse">
          <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
            v-bind:class="{ 'is-active' : !isOpen }" @click="toggleBodyClass('closed-sidebar')">
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>
        </div>
      </div>
    </div>
    <div class="app-header__menu">
      <div class="q-mt-lg">
        <UserArea />
        <div class="alimqr2">
          <q-btn
            flat
            dense
            color="white"
            class="topnoti"
            @click="getEmergency"
            icon="phone_in_talk">
          </q-btn>
        </div>
        <div class="alimBell">
          <q-btn
            flat
            dense
            color="white"
            class="topnoti"
            icon="notifications"
            @click="goApprPage">
            <!-- @click="goPage('/sys/appr/doc/apprTodo')"> -->
            <q-badge color="orange" v-if="isBadge" :label="notiCnt" floating rounded />
          </q-btn>
        </div>
        <div class="aliPush">
          <q-btn
            v-if="isPushMessage"
            flat
            dense
            color="white"
            class="topnoti"
            icon="message"
            @click="goPushPage">
            <q-badge floating color="red" rounded class="animatedBadge" />
          </q-btn>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import UserArea from "./Header/HeaderUserArea";
import selectConfig from '@/js/selectConfig';

export default {
  name: "Header",
  components: {
    UserArea,
  },

  data() {
    return {
      notiCnt: 0,
      isOpen: false,
      isOpenMobileMenu: false,
      searchAreaShow: false,
      isBadge: false,
      apprCntUrl: '',
      apprCnt1: 0,
      apprCnt2: 0,
      apprCnt3: 0,
      apprCnt4: 0,
      pushUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      pushMessages: [],
    };
  },
  props: {
    headerbg: String
  },
  computed: {
    changeSearchAreaShow() {
      return this.$store.state.app.searchAreaToggle;
    },
    isMain() {
      return this.$route.name === 'main' || this.$route.path === '/' || this.$route.path === '/main/index';
    },
    menuTitle() {
      let data = this.$_.find(this.$store.getters.addRouters, { name: this.$route.name });
      return data ? data.meta.title: ''
    },
    pushMessageCntTotal() {
      return this.pushMessages && this.pushMessages.length > 0 ?
        this.pushMessages.length : 0
    },
    isPushMessage() {
      return this.pushMessageCntTotal > 0
    },
  },
  mounted() {
    this.init();
  },
  watch: {
    changeSearchAreaShow() {
      this.searchAreaShow = this.$_.clone(this.changeSearchAreaShow)
    }
  },
  methods: {
    init() {
      this.apprCntUrl = selectConfig.sys.appr.list.mycnt.url;
      this.pushUrl = selectConfig.sys.push.history.list.url;
      this.getApprCnt();
      this.getPushMessage();
    },
    getPushMessage() {
      this.$http.url = this.pushUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        userId: this.$store.getters.user.userId
      };
      this.$http.request((_result) => {
        this.pushMessages = _result.data
      },);
    },
    goApprPage() {
      this.popupOptions.title = '결재 알림';
      this.popupOptions.target = () => import(`${'@/pages/sys/appr/doc/apprTodo.vue'}`);
      this.popupOptions.param = {
        approvalDocType: 'TODO',
      };
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeApprPopup;
    },
    closeApprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getApprCnt();
    },
    goPage(_page) {
      this.$comm.movePage(this.$router, _page)
      const el = document.body;
      el.classList.remove('closed-sidebar-open');
    },
    getApprCnt() {
      this.$http.url = this.apprCntUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        approvalUserId: this.$store.getters.user.userId
      };
      this.$http.request((_result) => {
        this.apprCnt1 = _result.data.apprCnt1;
        this.apprCnt2 = _result.data.apprCnt2;
        this.apprCnt3 = _result.data.apprCnt3;
        this.apprCnt4 = _result.data.apprCnt4;
        if (this.apprCnt1 + this.apprCnt2 + this.apprCnt3 + this.apprCnt4 > 0) {
          this.isBadge = true;
          // this.notiCnt = this.apprCnt1 + this.apprCnt2 + this.apprCnt3 + this.apprCnt4;
          this.notiCnt = this.apprCnt1 + this.apprCnt2 + this.apprCnt3;
        }
      },);
    },
    toggleBodyClass(className) {
      const el = document.body;
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    toggleMobile(className) {
      const el = document.body;

      if (el.classList.contains(className)) {
        el.classList.remove(className);
      } else {
        el.classList.add(className);

        let _leftMenu = document.getElementsByClassName('left-sidebar-menu')
        if (_leftMenu && _leftMenu[0]) {
          _leftMenu[0].focus();
        }
      }
    },
    toggleMobile2(className) {
      const el = document.body;
      this.isOpenMobileMenu = !this.isOpenMobileMenu;

      if (this.isOpenMobileMenu) {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    routeMain() {
      this.$router.push('/');
    },
    searchAreaToggle() {
      this.$store.dispatch('searchAreaToggle', this.searchAreaShow);
      const el = document.body;
      el.classList.remove('closed-sidebar-open');
    },
    getEmergency() {
      this.popupOptions.title = '응급상황 대응';
      this.popupOptions.target = () => import(`${'@/pages/common/eme/EmeContactPop.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopupEmergency;
    },
    closePopupEmergency() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    goPushPage() {
      this.popupOptions.title = 'PUSH 알림';
      this.popupOptions.target = () => import(`${'@/pages/common/push/pushPop.vue'}`);
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopupPush;
    },
    closePopupPush() {
      this.getPushMessage();
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
<style lang="sass">
.menucollapse .hamburger-inner, .menucollapse .hamburger-inner::before, .menucollapse .hamburger-inner::after
  background: #fff !important
.searchAreaShowBtn
  position: fixed
  top: 7px
  right: 40px
  z-index: 9999
  .q-fab__label--internal-right
    padding-left: 0em
.alimBell
  position: fixed
  top: 7px
  left: 5px
  z-index: 9999
  .q-badge--floating
    top: 0px !important
    right: 0px !important
.aliPush
  position: fixed
  top: 7px
  right: 45px
  z-index: 9999
  .q-badge--floating
    top: 2px !important
    right: 2px !important
.alimqr2
  position: fixed
  top: 7px
  left: 45px
  z-index: 9999
.alimqr
  position: fixed
  top: 7px
  right: 5px
  z-index: 9999
.alimHome
  position: fixed
  top: 6px
  left: 80px
  z-index: 9999
.login-main-top
  text-align: center
  font-weight: 700
  margin: 0 auto
  font-size: 1.6em
</style>
