/* eslint-disable */
import axios from 'axios';
import {
  sso,
  outerLoginByUserAccount,
  logout,
  getUserInfo,
  loginByUserAccount,
  validToken,
} from './login';
import {
  getToken,
  getAccessToken,
  getRefreshToken,
  setToken,
  setAccessToken,
  setRefreshToken,
  removeToken,
  removeRefreshToken,
  removeAccessToken,
} from '@/utils/auth';

const user = {
  state: {
    user: {},
    auths: [],
    depts: [],
    vendors: [],
    // Google OTP 2차인증용 샘플
    // otpYn: '',
    // otpSecKey: '',
    // otpUrl: '',
    code: '',
    token: getToken(),
    roles: [],
    deptAuthGrp: null,
  },

  mutations: {
    SET_CODE: (state, code) => {
      state.code = code;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_ACCESS_TOKEN: (state, token) => {
      state.accessToken = token;
    },
    SET_REFRESH_TOKEN: (state, token) => {
      state.refreshtoken = token;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_USER: (state, user) => {
      state.user = user;
    },
    SET_USER_AUTH: (state, auths) => {
      state.auths = auths;
    },
    SET_DEPT_LIST: (state, depts) => {
      state.depts = depts;
    },
    SET_VENDOR_LIST: (state, vendors) => {
      state.vendors = vendors;
    },
    SET_AUTH_PLANT_DEPT: (state, deptAuthGrp) => {
      state.deptAuthGrp = deptAuthGrp;
    },
    SET_MENU_INFO: (state, menu) => {
      state.menu = menu;
    },
    // Google OTP 2차인증용 샘플
    // SET_OTP_YN: (state, otpYn) => {
    //   state.otpYn = otpYn;
    // },
    // SET_OTP_SECKEY: (state, otpSecKey) => {
    //   state.otpSecKey = otpSecKey;
    // },
    // SET_OTP_URL: (state, otpUrl) => {
    //   state.otpUrl = otpUrl;
    // }
  },

  actions: {
    // 사용자 계정 로그인
    sso({
      commit
    }, ) {
      return new Promise((resolve, reject) => {
        sso()
          .then(response => {
            const data = response.data;
            commit('SET_TOKEN', data.userId);
            commit('SET_ACCESS_TOKEN', data.accessToken);
            commit('SET_REFRESH_TOKEN', data.refreshToken);

            setToken(response.data.userId);
            setAccessToken(response.data.accessToken);
            setRefreshToken(response.data.refreshToken);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    // 사용자 계정 로그인
    OuterLoginByUserAccount({
      commit
    }, userInfo) {
      console.log(userInfo)
      const userId = userInfo.userId.trim();
      return new Promise((resolve, reject) => {
        outerLoginByUserAccount(userId)
          .then(response => {
            const data = response.data;
            commit('SET_TOKEN', data.userId);
            commit('SET_ACCESS_TOKEN', data.accessToken);
            commit('SET_REFRESH_TOKEN', data.refreshToken);
            // Google OTP 2차인증용 샘플
            // commit('SET_OTP_YN', data.otpYn);
            // commit('SET_OTP_SECKEY', data.otpSecKey);
            // commit('SET_OTP_URL', data.otpUrl);
            setToken(response.data.userId);
            setAccessToken(response.data.accessToken);
            setRefreshToken(response.data.refreshToken);
            resolve();
          })
          .catch(error => {
            // if (
            //   error.message.includes('undefined') &&
            //   error.message.includes('$emit')
            // ) {
            //   window.alert('연결할 수 없습니다. 관리자에게 문의하세요.');
            // } else {
            //   window.alert(error.response.data.message);
            // }
            reject(error);
          });
      });
    },

    // 사용자 계정 로그인
    LoginByUserAccount({
      commit
    }, userInfo) {
      const userId = userInfo.userId.trim();
      return new Promise((resolve, reject) => {
        loginByUserAccount(userId, userInfo.password, userInfo.otppassword)
          .then(response => {
            const data = response.data;
            commit('SET_TOKEN', data.userId);
            commit('SET_ACCESS_TOKEN', data.accessToken);
            commit('SET_REFRESH_TOKEN', data.refreshToken);
            // Google OTP 2차인증용 샘플
            // commit('SET_OTP_YN', data.otpYn);
            // commit('SET_OTP_SECKEY', data.otpSecKey);
            // commit('SET_OTP_URL', data.otpUrl);
            setToken(response.data.userId);
            setAccessToken(response.data.accessToken);
            setRefreshToken(response.data.refreshToken);
            resolve();
          })
          .catch(error => {
            // if (
            //   error.message.includes('undefined') &&
            //   error.message.includes('$emit')
            // ) {
            //   window.alert('연결할 수 없습니다. 관리자에게 문의하세요.');
            // } else {
            //   window.alert(error.response.data.message);
            // }
            reject(error);
          });
      });
    },

    // 사용자 정보 얻기
    GetUserInfo({
      commit,
      state
    }) {
      return new Promise((resolve, reject) => {
        getUserInfo(state.access)
          .then(response => {
            if (!response.data) {
              // mockjs는 맞춤 상태 코드를 지원하지 않으므로 다음과 같이 Interupt 가능
              reject('error');
            }
            const data = response.data;
            commit('SET_USER', data.user);
            commit('SET_USER_AUTH', data.auths);
            commit('SET_DEPT_LIST', data.depts);
            commit('SET_VENDOR_LIST', data.vendors);

            /**
             * 사용자 권한 및 사업장/부서접근 권한은 구성될 시에 가져오도록 처리
             */
            // if (data.auths && data.auths.length > 0) {
            //   // 사용자 roles 확인
            //   commit('SET_ROLES', data.auths);
            // } else {
            //   reject('getInfo: roles must be a non-null array !');
            // }
            // commit('SET_AUTH_PLANT_DEPT', data.deptAuthGrp);
            // commit('SET_MENU_INFO', data.menu);

            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    LogOut({
      commit,
      state
    }) {
      return new Promise((resolve, reject) => {
        logout(state.token)
          .then(() => {
            commit('SET_TOKEN', '');
            commit('SET_ACCESS_TOKEN', '');
            commit('SET_REFRESH_TOKEN', '');
            commit('SET_ROLES', []);
            // Google OTP 2차인증용 샘플
            // commit('SET_OTP_YN', '');
            // commit('SET_OTP_SECKEY', '');
            // commit('SET_OTP_URL', '');
            removeToken();
            removeAccessToken();
            removeRefreshToken();
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    // 前端 登出
    FedLogOut({
      commit
    }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '');
        commit('SET_ACCESS_TOKEN', '');
        commit('SET_REFRESH_TOKEN', '');
        // Google OTP 2차인증용 샘플
        // commit('SET_OTP_YN', '');
        // commit('SET_OTP_SECKEY', '');
        // commit('SET_OTP_URL', '');
        removeToken();
        removeAccessToken();
        removeRefreshToken();
        resolve();
      });
    },

    // AccessToken만료로 Refresh토큰으로
    UpdateTokenByRefreshToken({
      commit
    }, state) {
      const refreshToken = getRefreshToken();
      // 헤더에 설정.
      axios.defaults.headers.common['X-Authorization'] = refreshToken;
      return new Promise((resolve, reject) => {
        updateTokenByRefreshToken()
          .then(response => {
            const data = response.data;
            resolve();
          })
          .catch(error => {
            if (
              error.message.includes('undefined') &&
              error.message.includes('$emit')
            ) {
              window.alert('연결할 수 없습니다. 관리자에게 문의하세요.');
            } else {
              window.alert(error.response.data.message);
            }
            reject(error);
          });
      });
    },
    ValidCheckRefreshToken({
      commit
    }, state) {
      const refreshToken = getRefreshToken();
      // 헤더에 설정.
      axios.defaults.headers.common['X-Authorization'] = refreshToken;
      return new Promise((resolve, reject) => {
        updateTokenByRefreshToken()
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    // AccessToken만료로 Refresh토큰으로
    ValidCheckToken({
      commit
    }, state) {
      // 헤더에 설정.
      axios.defaults.headers.common['X-Authorization'] = getAccessToken();
      return new Promise((resolve, reject) => {
        validToken()
          .then(response => {
            const data = response.data;
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    
    Clear({
      commit,
      state
    }) {
      return new Promise((resolve, reject) => {
        commit('SET_TOKEN', '');
        commit('SET_ACCESS_TOKEN', '');
        commit('SET_REFRESH_TOKEN', '');
        commit('SET_ROLES', []);
        // Google OTP 2차인증용 샘플
        // commit('SET_OTP_YN', '');
        // commit('SET_OTP_SECKEY', '');
        // commit('SET_OTP_URL', '');
        removeToken();
        removeAccessToken();
        removeRefreshToken();
        resolve();
      });
    },

    ClearRoles({
      commit,
      state
    }) {
      return new Promise((resolve, reject) => {
        commit('SET_ROLES', []);
        resolve();
      });
    },
  },
};

export default user;
