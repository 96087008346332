var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-table", {
        ref: "compo-table",
        staticClass: "default-table mobile-table",
        class: [_vm.topBorderClass, _vm.isDashboard ? "isDashboardTable" : ""],
        style: { height: _vm.height },
        attrs: {
          tableId: _vm.tableId,
          "card-class": _vm.cardClass,
          grid: "",
          flat: "",
          bordered: "",
          fullscreen: _vm.fullscreen,
          separator: _vm.separator,
          data: _vm.data,
          columns: _vm.arrangColumns,
          "row-key": _vm.rowKey,
          selection: _vm.selection,
          selected: _vm.selected,
          "visible-columns": _vm.visibleColumns,
          filter: _vm.filter,
          dense: _vm.dense,
          loading: _vm.loading,
          "no-data-label": _vm.noDataLabel,
          "no-results-label": _vm.noResultLabel,
          pagination: _vm.initialPagination,
          "hide-pagination": false,
          "virtual-scroll": !_vm.usePaging,
          "virtual-scroll-slice-size": !_vm.usePaging ? 20 : 20,
          "virtual-scroll-item-size": !_vm.usePaging ? 48 : 0,
          "virtual-scroll-sticky-size-start": !_vm.usePaging ? 48 : 0,
          "hide-bottom": _vm.hideBottom,
          "hide-header": _vm.hideHeader,
        },
        on: {
          "update:selected": function ($event) {
            _vm.selected = $event
          },
          "update:pagination": function ($event) {
            _vm.initialPagination = $event
          },
          selection: _vm.changeSelection,
          "virtual-scroll": _vm.virtualScroll,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "loading",
              fn: function () {
                return [
                  _c("q-inner-loading", {
                    attrs: { showing: "", color: "primary" },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "no-data",
              fn: function ({ message }) {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "full-width row flex-center text-accent q-gutter-sm",
                    },
                    [
                      _c("q-icon", {
                        attrs: { size: "2em", name: "sentiment_dissatisfied" },
                      }),
                      _c("span", [_vm._v(" " + _vm._s(message) + " ")]),
                    ],
                    1
                  ),
                ]
              },
            },
            _vm.isTop
              ? {
                  key: "top",
                  fn: function () {
                    return [
                      [
                        _c(
                          "div",
                          { staticClass: "float-left" },
                          [
                            _vm._t("perfixTitle"),
                            _c(
                              "div",
                              { staticClass: "q-table__title" },
                              [
                                _vm.isTitle
                                  ? _c("i", {
                                      staticClass: "pe-7s-angle-right-circle",
                                    })
                                  : _vm._e(),
                                _vm.isTitle
                                  ? [_vm._v(" " + _vm._s(_vm.title) + " ")]
                                  : _vm._e(),
                                _vm._t("suffixTitle"),
                              ],
                              2
                            ),
                          ],
                          2
                        ),
                        _c(
                          "div",
                          { staticClass: "col-auto cardselectarea" },
                          [_vm._t("table-header-append")],
                          2
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "float-left",
                            class: [
                              { gridSearchIcon: _vm.isTitle },
                              { gridSearchIcon2: !_vm.isTitle },
                            ],
                          },
                          [
                            _vm.filtering
                              ? _c("q-input", {
                                  staticClass: "filtering-text",
                                  staticStyle: {
                                    "min-width": "150px",
                                    "max-width": "250px",
                                  },
                                  attrs: {
                                    dense: "",
                                    debounce: "300",
                                    color: "white",
                                    placeholder: "Search",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "append",
                                        fn: function () {
                                          return [
                                            _c("q-icon", {
                                              attrs: { name: "search" },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    4009527860
                                  ),
                                  model: {
                                    value: _vm.filter,
                                    callback: function ($$v) {
                                      _vm.filter = $$v
                                    },
                                    expression: "filter",
                                  },
                                })
                              : _vm._e(),
                            _vm._t("customFilter"),
                          ],
                          2
                        ),
                        _c("div", [_vm._t("table-chip")], 2),
                        _c("q-space"),
                        _c(
                          "div",
                          { staticClass: "q-mt-md gridbtntop" },
                          [_vm._t("table-button")],
                          2
                        ),
                        _c(
                          "div",
                          { staticClass: "float-right" },
                          [
                            _vm._t("prefixContent"),
                            _vm.isExcelDown && _vm.data.length > 0
                              ? _c("q-btn", {
                                  staticClass: "tableExcelIcon",
                                  attrs: { flat: "", dense: "" },
                                  on: { click: _vm.downloadExcel },
                                })
                              : _vm._e(),
                            _vm.isFullScreen
                              ? _c("q-btn", {
                                  staticClass: "tabletopicon tablefullscreen",
                                  attrs: {
                                    flat: "",
                                    dense: "",
                                    color: "grey-6",
                                    icon: _vm.fullscreen
                                      ? "fullscreen_exit"
                                      : "fullscreen",
                                  },
                                  on: { click: _vm.clickFullScreen },
                                })
                              : _vm._e(),
                            _vm.columnSetting
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "table-setting gridsettingIcon",
                                  },
                                  [
                                    _c(
                                      "q-btn",
                                      {
                                        staticClass: "tabletopicon",
                                        attrs: {
                                          flat: "",
                                          dense: "",
                                          color: "grey-6",
                                          icon: "more_vert",
                                        },
                                      },
                                      [
                                        _c(
                                          "q-menu",
                                          {
                                            attrs: {
                                              "transition-show": "jump-down",
                                              "transition-hide": "jump-up",
                                            },
                                          },
                                          [
                                            _c(
                                              "q-list",
                                              {
                                                staticClass: "configColumnSet",
                                                attrs: { dense: "" },
                                              },
                                              _vm._l(
                                                _vm.columnsControl,
                                                function (column, idx) {
                                                  return _c(
                                                    "q-item",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "ripple",
                                                          rawName: "v-ripple",
                                                        },
                                                      ],
                                                      key: idx,
                                                      attrs: {
                                                        tag: "label",
                                                        disable:
                                                          column.disabled,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "q-item-section",
                                                        {
                                                          attrs: {
                                                            side: "",
                                                            top: "",
                                                          },
                                                        },
                                                        [
                                                          column.disabled
                                                            ? _c("q-checkbox", {
                                                                staticClass:
                                                                  "tableCheckBox",
                                                                attrs: {
                                                                  disable: true,
                                                                  color:
                                                                    "orange-7",
                                                                  value: true,
                                                                },
                                                              })
                                                            : _c("q-checkbox", {
                                                                staticClass:
                                                                  "tableCheckBox",
                                                                attrs: {
                                                                  color:
                                                                    "orange-7",
                                                                },
                                                                nativeOn: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.colChange(
                                                                        column
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    column.check,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        column,
                                                                        "check",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "column.check",
                                                                },
                                                              }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "q-item-section",
                                                        [
                                                          _c("q-item-label", [
                                                            _vm._v(
                                                              _vm._s(
                                                                column.label
                                                              )
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm._t("customTableTitleEditor"),
                      ],
                    ]
                  },
                  proxy: true,
                }
              : null,
            {
              key: "item",
              fn: function (props) {
                return [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { class: _vm.gridItemClass },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "mobile-table-header bg-grey-5 text-grey-9 q-pl-sm",
                          },
                          [
                            _vm.selection !== "none" && _vm.editable
                              ? _c("q-checkbox", {
                                  attrs: { dense: "", label: props.row.name },
                                  model: {
                                    value: props.selected,
                                    callback: function ($$v) {
                                      _vm.$set(props, "selected", $$v)
                                    },
                                    expression: "props.selected",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" # " + _vm._s(props.rowIndex + 1) + " "),
                          ],
                          1
                        ),
                        _c(
                          "q-list",
                          {
                            staticClass: "mobile-table-body",
                            attrs: { bordered: "", separator: "" },
                          },
                          [
                            _c("q-item", [
                              _c(
                                "div",
                                { staticClass: "row" },
                                [
                                  _vm._l(props.cols, function (col, colIndex) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          key: colIndex,
                                          class: _vm.getColClass(col.colClass),
                                        },
                                        [
                                          _c(
                                            "q-item-section",
                                            [
                                              _vm.getColViews(props, col)
                                                ? _c(
                                                    "q-item-label",
                                                    { attrs: { caption: "" } },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "mobile-table-label",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(col.label)
                                                          ),
                                                        ]
                                                      ),
                                                      _vm.check(col.type)
                                                        ? [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "mobile-table-label-undefined",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    props.row[
                                                                      col.name
                                                                    ]
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        : [
                                                            col.type === "link"
                                                              ? [
                                                                  props.row[
                                                                    col.name
                                                                  ]
                                                                    ? _c(
                                                                        "q-btn",
                                                                        {
                                                                          staticClass:
                                                                            "tableinnerBtn",
                                                                          class:
                                                                            {
                                                                              "tableinnerBtn-left":
                                                                                col.align ===
                                                                                "left"
                                                                                  ? true
                                                                                  : false,
                                                                              "tableinnerBtn-right":
                                                                                col.align ===
                                                                                "right"
                                                                                  ? true
                                                                                  : false,
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              flat: "",
                                                                              align:
                                                                                col.align,
                                                                              color:
                                                                                "blue-6",
                                                                              label:
                                                                                "",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.stopPropagation()
                                                                                return _vm.linkClick(
                                                                                  props,
                                                                                  col
                                                                                )
                                                                              },
                                                                          },
                                                                          scopedSlots:
                                                                            _vm._u(
                                                                              [
                                                                                {
                                                                                  key: "default",
                                                                                  fn: function () {
                                                                                    return [
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          domProps:
                                                                                            {
                                                                                              innerHTML:
                                                                                                _vm._s(
                                                                                                  _vm.$comm.convertHtml(
                                                                                                    props
                                                                                                      .row[
                                                                                                      col
                                                                                                        .name
                                                                                                    ]
                                                                                                  )
                                                                                                ),
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ]
                                                                                  },
                                                                                  proxy: true,
                                                                                },
                                                                              ],
                                                                              null,
                                                                              true
                                                                            ),
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              : _vm._e(),
                                                            col.type === "text"
                                                              ? [
                                                                  _c(
                                                                    "c-text-column",
                                                                    {
                                                                      attrs: {
                                                                        editable:
                                                                          _vm.editable,
                                                                        disabled:
                                                                          props
                                                                            .row[
                                                                            _vm
                                                                              .checkDisableColumn
                                                                          ] ||
                                                                          _vm.checkEnable(
                                                                            col,
                                                                            props.row,
                                                                            props
                                                                          ),
                                                                        col: col,
                                                                        props:
                                                                          props,
                                                                        colorClass:
                                                                          _vm.checkColorCloass(
                                                                            col,
                                                                            props.row
                                                                          ),
                                                                      },
                                                                      on: {
                                                                        datachange:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.datachange(
                                                                              props,
                                                                              col
                                                                            )
                                                                          },
                                                                      },
                                                                    }
                                                                  ),
                                                                ]
                                                              : col.type ===
                                                                "textarea"
                                                              ? [
                                                                  _c(
                                                                    "c-textarea-column",
                                                                    {
                                                                      attrs: {
                                                                        editable:
                                                                          _vm.editable,
                                                                        disabled:
                                                                          props
                                                                            .row[
                                                                            _vm
                                                                              .checkDisableColumn
                                                                          ] ||
                                                                          _vm.checkEnable(
                                                                            col,
                                                                            props.row,
                                                                            props
                                                                          ),
                                                                        col: col,
                                                                        props:
                                                                          props,
                                                                      },
                                                                      on: {
                                                                        datachange:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.datachange(
                                                                              props,
                                                                              col
                                                                            )
                                                                          },
                                                                      },
                                                                    }
                                                                  ),
                                                                ]
                                                              : col.type ===
                                                                "select"
                                                              ? [
                                                                  _c(
                                                                    "c-select",
                                                                    {
                                                                      attrs: {
                                                                        stype:
                                                                          "tableselect",
                                                                        editable:
                                                                          _vm.editable,
                                                                        disabled:
                                                                          !_vm.editable ||
                                                                          props
                                                                            .row[
                                                                            _vm
                                                                              .checkDisableColumn
                                                                          ] ||
                                                                          _vm.checkEnable(
                                                                            col,
                                                                            props.row,
                                                                            props
                                                                          ),
                                                                        isChip:
                                                                          col.isChip,
                                                                        comboItems:
                                                                          col.comboItems,
                                                                        type: !col.none
                                                                          ? "edit"
                                                                          : "",
                                                                        itemText:
                                                                          col.itemText
                                                                            ? col.itemText
                                                                            : "codeName",
                                                                        itemValue:
                                                                          col.itemValue
                                                                            ? col.itemValue
                                                                            : "code",
                                                                      },
                                                                      on: {
                                                                        datachange:
                                                                          (
                                                                            val
                                                                          ) =>
                                                                            _vm.changeSelect(
                                                                              val,
                                                                              props,
                                                                              col
                                                                            ),
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          props
                                                                            .row[
                                                                            col
                                                                              .name
                                                                          ],
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              props.row,
                                                                              col.name,
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "props.row[col.name]",
                                                                      },
                                                                    }
                                                                  ),
                                                                ]
                                                              : col.type ===
                                                                "date"
                                                              ? [
                                                                  _c(
                                                                    "c-datepicker",
                                                                    {
                                                                      staticClass:
                                                                        "tableDatepicker",
                                                                      attrs: {
                                                                        dense:
                                                                          "",
                                                                        type: col.dateType
                                                                          ? col.dateType
                                                                          : "date",
                                                                        disabled:
                                                                          _vm.checkEnable(
                                                                            col,
                                                                            props.row,
                                                                            props
                                                                          ) ||
                                                                          !_vm.editable ||
                                                                          props
                                                                            .row[
                                                                            _vm
                                                                              .checkDisableColumn
                                                                          ],
                                                                        range:
                                                                          col.range ===
                                                                          true
                                                                            ? true
                                                                            : false,
                                                                        minuteStep:
                                                                          col.minuteStep
                                                                            ? col.minuteStep
                                                                            : 1,
                                                                        start:
                                                                          col.start
                                                                            ? col.start
                                                                            : "",
                                                                        end: col.end
                                                                          ? col.end
                                                                          : "",
                                                                      },
                                                                      on: {
                                                                        datachange:
                                                                          (
                                                                            val
                                                                          ) =>
                                                                            _vm.datachange(
                                                                              props,
                                                                              col
                                                                            ),
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          props
                                                                            .row[
                                                                            col
                                                                              .name
                                                                          ],
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              props.row,
                                                                              col.name,
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "props.row[col.name]",
                                                                      },
                                                                    }
                                                                  ),
                                                                ]
                                                              : col.type ===
                                                                "plant"
                                                              ? [
                                                                  _c(
                                                                    "c-plant",
                                                                    {
                                                                      attrs: {
                                                                        isTable: true,
                                                                        stype:
                                                                          "tableselect",
                                                                        editable:
                                                                          _vm.editable,
                                                                        label:
                                                                          "",
                                                                        type: "none",
                                                                      },
                                                                      on: {
                                                                        datachange:
                                                                          (
                                                                            val
                                                                          ) =>
                                                                            _vm.datachange(
                                                                              props,
                                                                              col
                                                                            ),
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          props
                                                                            .row[
                                                                            col
                                                                              .name
                                                                          ],
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              props.row,
                                                                              col.name,
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "props.row[col.name]",
                                                                      },
                                                                    }
                                                                  ),
                                                                ]
                                                              : col.type ===
                                                                "number"
                                                              ? [
                                                                  _c(
                                                                    "c-number-column",
                                                                    {
                                                                      attrs: {
                                                                        editable:
                                                                          _vm.editable,
                                                                        disabled:
                                                                          props
                                                                            .row[
                                                                            _vm
                                                                              .checkDisableColumn
                                                                          ] ||
                                                                          _vm.checkEnable(
                                                                            col,
                                                                            props.row,
                                                                            props
                                                                          ),
                                                                        col: col,
                                                                        props:
                                                                          props,
                                                                        colorClass:
                                                                          _vm.checkColorCloass(
                                                                            col,
                                                                            props.row
                                                                          ),
                                                                      },
                                                                      on: {
                                                                        datachange:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.datachange(
                                                                              props,
                                                                              col
                                                                            )
                                                                          },
                                                                      },
                                                                    }
                                                                  ),
                                                                ]
                                                              : col.type ===
                                                                "check"
                                                              ? [
                                                                  _c(
                                                                    "q-checkbox",
                                                                    {
                                                                      staticClass:
                                                                        "tableCheckBox",
                                                                      attrs: {
                                                                        dense:
                                                                          "",
                                                                        color:
                                                                          "orange-7",
                                                                        disable:
                                                                          _vm.checkEnable(
                                                                            col,
                                                                            props.row,
                                                                            props
                                                                          ) ||
                                                                          !_vm.editable,
                                                                        "true-value":
                                                                          col.true
                                                                            ? col.true
                                                                            : "O",
                                                                        "false-value":
                                                                          col.false
                                                                            ? col.false
                                                                            : "X",
                                                                      },
                                                                      on: {
                                                                        input: (
                                                                          val
                                                                        ) =>
                                                                          _vm.datachange(
                                                                            props,
                                                                            col
                                                                          ),
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          props
                                                                            .row[
                                                                            col
                                                                              .name
                                                                          ],
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              props.row,
                                                                              col.name,
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "props.row[col.name]",
                                                                      },
                                                                    }
                                                                  ),
                                                                ]
                                                              : col.type ===
                                                                "dept"
                                                              ? [
                                                                  _vm.editable
                                                                    ? _c(
                                                                        "c-dept",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              type: "edit",
                                                                              label:
                                                                                "",
                                                                              disabled:
                                                                                !_vm.editable ||
                                                                                props
                                                                                  .row[
                                                                                  _vm
                                                                                    .checkDisableColumn
                                                                                ] ||
                                                                                _vm.checkEnable(
                                                                                  col,
                                                                                  props.row,
                                                                                  props
                                                                                ),
                                                                            },
                                                                          on: {
                                                                            setDeptName:
                                                                              (
                                                                                val
                                                                              ) =>
                                                                                (props.row[
                                                                                  col.name
                                                                                ] =
                                                                                  val),
                                                                            datachange:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.datachange(
                                                                                  props,
                                                                                  col
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                props
                                                                                  .row[
                                                                                  col
                                                                                    .deptCd
                                                                                ],
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    props.row,
                                                                                    col.deptCd,
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "props.row[col.deptCd]",
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                props
                                                                                  .row[
                                                                                  col
                                                                                    .name
                                                                                ]
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                ]
                                                              : col.type ===
                                                                "user"
                                                              ? [
                                                                  _vm.checkUserTd(
                                                                    col,
                                                                    props
                                                                  )
                                                                    ? _c(
                                                                        "q-btn",
                                                                        {
                                                                          staticClass:
                                                                            "tableinnerBtn tableinnerUserSearch",
                                                                          class:
                                                                            {
                                                                              "tableinnerUserSearch-left":
                                                                                col.align ===
                                                                                "left"
                                                                                  ? true
                                                                                  : false,
                                                                              "tableinnerUserSearch-right":
                                                                                col.align ===
                                                                                "right"
                                                                                  ? true
                                                                                  : false,
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              flat: "",
                                                                              color:
                                                                                "blue-6",
                                                                              disable:
                                                                                _vm.checkEnable(
                                                                                  col,
                                                                                  props.row,
                                                                                  props
                                                                                ) ||
                                                                                !_vm.editable ||
                                                                                props
                                                                                  .row[
                                                                                  _vm
                                                                                    .checkDisableColumn
                                                                                ],
                                                                              icon: _vm.checkEnable(
                                                                                col,
                                                                                props.row,
                                                                                props
                                                                              )
                                                                                ? "search_off"
                                                                                : "search",
                                                                              label:
                                                                                props
                                                                                  .row[
                                                                                  col
                                                                                    .name
                                                                                ],
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.stopPropagation()
                                                                                return _vm.openUserPop(
                                                                                  props.row,
                                                                                  col,
                                                                                  "td"
                                                                                )
                                                                              },
                                                                          },
                                                                        }
                                                                      )
                                                                    : [
                                                                        _c(
                                                                          "c-text-column",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                editable:
                                                                                  _vm.editable,
                                                                                disabled:
                                                                                  props
                                                                                    .row[
                                                                                    _vm
                                                                                      .checkDisableColumn
                                                                                  ] ||
                                                                                  _vm.checkEnable(
                                                                                    col,
                                                                                    props.row,
                                                                                    props
                                                                                  ),
                                                                                col: col,
                                                                                props:
                                                                                  props,
                                                                              },
                                                                            on: {
                                                                              datachange:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.datachange(
                                                                                    props,
                                                                                    col
                                                                                  )
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                      ],
                                                                ]
                                                              : col.type ===
                                                                "vendor"
                                                              ? _c("c-vendor", {
                                                                  attrs: {
                                                                    disabled:
                                                                      !_vm.editable ||
                                                                      props.row[
                                                                        _vm
                                                                          .checkDisableColumn
                                                                      ] ||
                                                                      _vm.checkEnable(
                                                                        col,
                                                                        props.row,
                                                                        props
                                                                      ),
                                                                    type: "edit",
                                                                    label: "",
                                                                  },
                                                                  on: {
                                                                    dataChange:
                                                                      (
                                                                        val,
                                                                        oldVal,
                                                                        vendor
                                                                      ) =>
                                                                        _vm.vendorChange(
                                                                          props,
                                                                          col,
                                                                          vendor
                                                                        ),
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      props.row[
                                                                        col.name
                                                                      ],
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          props.row,
                                                                          col.name,
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "props.row[col.name]",
                                                                  },
                                                                })
                                                              : col.type ===
                                                                "facility"
                                                              ? _c(
                                                                  "c-facility",
                                                                  {
                                                                    attrs: {
                                                                      disabled:
                                                                        !_vm.editable ||
                                                                        props
                                                                          .row[
                                                                          _vm
                                                                            .checkDisableColumn
                                                                        ] ||
                                                                        _vm.checkEnable(
                                                                          col,
                                                                          props.row,
                                                                          props
                                                                        ),
                                                                      detail: false,
                                                                      type: "edit",
                                                                      label: "",
                                                                    },
                                                                    on: {
                                                                      dataChange:
                                                                        (
                                                                          facility
                                                                        ) =>
                                                                          _vm.facilityChange(
                                                                            props,
                                                                            col,
                                                                            facility
                                                                          ),
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        props
                                                                          .row[
                                                                          col
                                                                            .name
                                                                        ],
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            props.row,
                                                                            col.name,
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "props.row[col.name]",
                                                                    },
                                                                  }
                                                                )
                                                              : col.type ===
                                                                "equip"
                                                              ? _c("c-equip", {
                                                                  attrs: {
                                                                    disabled:
                                                                      !_vm.editable ||
                                                                      props.row[
                                                                        _vm
                                                                          .checkDisableColumn
                                                                      ] ||
                                                                      _vm.checkEnable(
                                                                        col,
                                                                        props.row,
                                                                        props
                                                                      ),
                                                                    type: "edit",
                                                                    label: "",
                                                                    name: "equipmentCd",
                                                                  },
                                                                  on: {
                                                                    dataChange:
                                                                      (equip) =>
                                                                        _vm.equipChange(
                                                                          props,
                                                                          col,
                                                                          equip
                                                                        ),
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      props.row[
                                                                        col.name
                                                                      ],
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          props.row,
                                                                          col.name,
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "props.row[col.name]",
                                                                  },
                                                                })
                                                              : col.type ===
                                                                "location"
                                                              ? _c(
                                                                  "c-location",
                                                                  {
                                                                    attrs: {
                                                                      disabled:
                                                                        !_vm.editable ||
                                                                        props
                                                                          .row[
                                                                          _vm
                                                                            .checkDisableColumn
                                                                        ] ||
                                                                        _vm.checkEnable(
                                                                          col,
                                                                          props.row,
                                                                          props
                                                                        ),
                                                                      type: "edit",
                                                                      label: "",
                                                                    },
                                                                    on: {
                                                                      dataChange:
                                                                        (
                                                                          location
                                                                        ) =>
                                                                          _vm.locationChange(
                                                                            props,
                                                                            col,
                                                                            location
                                                                          ),
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        props
                                                                          .row[
                                                                          col
                                                                            .name
                                                                        ],
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            props.row,
                                                                            col.name,
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "props.row[col.name]",
                                                                    },
                                                                  }
                                                                )
                                                              : col.type ===
                                                                "attach"
                                                              ? [
                                                                  _c(
                                                                    "c-upload-column",
                                                                    {
                                                                      staticClass:
                                                                        "tableUploadColumn",
                                                                      attrs: {
                                                                        dense:
                                                                          "",
                                                                        editable:
                                                                          !_vm.checkEnable(
                                                                            col,
                                                                            props.row,
                                                                            props
                                                                          ) &&
                                                                          _vm.editable &&
                                                                          !props
                                                                            .row[
                                                                            _vm
                                                                              .checkDisableColumn
                                                                          ],
                                                                        imageRestriction:
                                                                          col.imageRestriction,
                                                                        col: col,
                                                                        row: props.row,
                                                                        uploaderSetting:
                                                                          _vm.uploaderSetting,
                                                                        colUploaderSetting:
                                                                          col.uploaderSetting,
                                                                        change:
                                                                          _vm.change,
                                                                      },
                                                                      on: {
                                                                        "update:row":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.$set(
                                                                              props,
                                                                              "row",
                                                                              $event
                                                                            )
                                                                          },
                                                                        "update:uploaderSetting":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            _vm.uploaderSetting =
                                                                              $event
                                                                          },
                                                                        "update:uploader-setting":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            _vm.uploaderSetting =
                                                                              $event
                                                                          },
                                                                        "update:colUploaderSetting":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.$set(
                                                                              col,
                                                                              "uploaderSetting",
                                                                              $event
                                                                            )
                                                                          },
                                                                        "update:col-uploader-setting":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.$set(
                                                                              col,
                                                                              "uploaderSetting",
                                                                              $event
                                                                            )
                                                                          },
                                                                        "update:change":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            _vm.change =
                                                                              $event
                                                                          },
                                                                        "upload-change":
                                                                          (
                                                                            data
                                                                          ) => {
                                                                            _vm.uploadChange(
                                                                              data,
                                                                              props,
                                                                              col
                                                                            )
                                                                          },
                                                                      },
                                                                    }
                                                                  ),
                                                                ]
                                                              : col.type ===
                                                                "datetime"
                                                              ? [
                                                                  _c(
                                                                    "c-datepicker",
                                                                    {
                                                                      staticClass:
                                                                        "tableDatepicker",
                                                                      attrs: {
                                                                        dense:
                                                                          "",
                                                                        type: col.datetimeType
                                                                          ? col.datetimeType
                                                                          : "time",
                                                                        timePickerOptions:
                                                                          _vm.setTimePickerOptions(
                                                                            props,
                                                                            col
                                                                          ),
                                                                        range:
                                                                          col.range ===
                                                                          true
                                                                            ? true
                                                                            : false,
                                                                        disabled:
                                                                          _vm.checkEnable(
                                                                            col,
                                                                            props.row,
                                                                            props
                                                                          ) ||
                                                                          !_vm.editable ||
                                                                          props
                                                                            .row[
                                                                            _vm
                                                                              .checkDisableColumn
                                                                          ],
                                                                        minuteStep:
                                                                          col.minuteStep
                                                                            ? col.minuteStep
                                                                            : 1,
                                                                      },
                                                                      on: {
                                                                        datachange:
                                                                          (
                                                                            val
                                                                          ) =>
                                                                            _vm.datachange(
                                                                              props,
                                                                              col
                                                                            ),
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          props
                                                                            .row[
                                                                            col
                                                                              .name
                                                                          ],
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              props.row,
                                                                              col.name,
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "props.row[col.name]",
                                                                      },
                                                                    }
                                                                  ),
                                                                ]
                                                              : col.type ===
                                                                "multiSelect"
                                                              ? _c(
                                                                  "c-multi-select",
                                                                  {
                                                                    attrs: {
                                                                      stype:
                                                                        "tableselect",
                                                                      editable:
                                                                        _vm.editable,
                                                                      disabled:
                                                                        _vm.checkEnable(
                                                                          col,
                                                                          props.row,
                                                                          props
                                                                        ),
                                                                      isObject:
                                                                        col.isObject,
                                                                      valueText:
                                                                        col.valueText
                                                                          ? col.valueText
                                                                          : "codeName",
                                                                      valueKey:
                                                                        col.valueKey
                                                                          ? col.valueKey
                                                                          : "code",
                                                                      comboItems:
                                                                        col.comboItems,
                                                                      itemText:
                                                                        col.itemText
                                                                          ? col.itemText
                                                                          : "codeName",
                                                                      itemValue:
                                                                        col.itemValue
                                                                          ? col.itemValue
                                                                          : "code",
                                                                    },
                                                                    on: {
                                                                      datachange:
                                                                        (val) =>
                                                                          _vm.datachange(
                                                                            props,
                                                                            col
                                                                          ),
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        props
                                                                          .row[
                                                                          col
                                                                            .name
                                                                        ],
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            props.row,
                                                                            col.name,
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "props.row[col.name]",
                                                                    },
                                                                  }
                                                                )
                                                              : col.type ===
                                                                "custom"
                                                              ? [
                                                                  _vm._t(
                                                                    "customArea",
                                                                    null,
                                                                    {
                                                                      props:
                                                                        props,
                                                                      col: col,
                                                                    }
                                                                  ),
                                                                ]
                                                              : col.type ===
                                                                "cost"
                                                              ? _c(
                                                                  "span",
                                                                  [
                                                                    _c(
                                                                      "c-number-column",
                                                                      {
                                                                        attrs: {
                                                                          editable: false,
                                                                          col: col,
                                                                          props:
                                                                            props,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                    ],
                                                    2
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      col.isSub
                                        ? _c(
                                            "q-item-section",
                                            {
                                              key: colIndex,
                                              attrs: { side: "", top: "" },
                                            },
                                            [
                                              _c(
                                                "q-item-label",
                                                { attrs: { caption: "" } },
                                                [_vm._v("5 min ago")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  }),
                                ],
                                2
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              },
            },
            {
              key: "bottom",
              fn: function () {
                return [
                  _vm._v(
                    " 총 건수 : " + _vm._s(_vm.data ? _vm.data.length : 0) + " "
                  ),
                  _c("q-space"),
                  _vm.usePaging
                    ? [
                        _c("q-select", {
                          staticClass: "tablePagingInput",
                          staticStyle: {
                            "min-width": "50px",
                            "max-width": "70px",
                          },
                          attrs: {
                            outlined: "",
                            dense: "",
                            options: _vm.pageItems,
                            color: "teal",
                          },
                          on: { input: _vm.changePageNumber },
                          model: {
                            value: _vm.pagePerRow.pageNumber,
                            callback: function ($$v) {
                              _vm.$set(_vm.pagePerRow, "pageNumber", $$v)
                            },
                            expression: "pagePerRow.pageNumber",
                          },
                        }),
                        _c("q-pagination", {
                          staticClass: "tablePagingBtn",
                          attrs: {
                            max: _vm.pagesNumber,
                            "max-pages": 5,
                            "direction-links": "",
                            "boundary-links": "",
                            "icon-first": "skip_previous",
                            "icon-last": "skip_next",
                            color: "teal-5",
                          },
                          model: {
                            value: _vm.initialPagination.page,
                            callback: function ($$v) {
                              _vm.$set(_vm.initialPagination, "page", $$v)
                            },
                            expression: "initialPagination.page",
                          },
                        }),
                      ]
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      _vm.preview && _vm.preview.src
        ? _c(
            "q-dialog",
            {
              attrs: { position: "bottom" },
              model: {
                value: _vm.preview.isShow,
                callback: function ($$v) {
                  _vm.$set(_vm.preview, "isShow", $$v)
                },
                expression: "preview.isShow",
              },
            },
            [
              _c(
                "q-card",
                { staticClass: "preview-image-card" },
                [
                  _c("q-img", { attrs: { src: _vm.preview.src } }),
                  _c(
                    "q-card-section",
                    [
                      _c("q-btn", {
                        staticClass: "absolute",
                        staticStyle: {
                          top: "0",
                          right: "12px",
                          transform: "translateY(-50%)",
                        },
                        attrs: { fab: "", color: "primary", icon: "save_alt" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.fileDown(_vm.preview)
                          },
                        },
                      }),
                      _c("div", { staticClass: "row no-wrap items-center" }, [
                        _c("div", { staticClass: "col text-h6" }, [
                          _vm._v(" " + _vm._s(_vm.preview.oriFileNm) + " "),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-auto text-grey text-caption q-pt-md row no-wrap items-center",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$comm.bytesToSize(_vm.preview.fileSize)
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }