<!--
목적 : 장소 컴포넌트
-->
<template>
  <div class="fix-height">
    <q-field
      ref="customLocation"
      class="customLocation"
      :dense="dense"
      stack-label
      bottom-slots
      color="orange-custom"
      :label="label"
      :disable="disabled||!editable"
      :rules="!required ? null : [val => !!val || '']"
      v-model="valueText"
      >
      <template v-slot:label v-if="label">
        <div class="row items-center all-pointer-events">
          <b>
            <i v-if="!required" class="pe-7s-note labelfrontIcon searchAreaLabelIcon"></i>
            <font class="formLabelTitle">{{label}}</font>
            <i v-if="required" class="material-icons labelfrontIcon text-requiredColor">check</i>
          </b>
        </div>
      </template>
      <template v-slot:control>
        <template v-if="!isMultiple">
          <template v-if="!isEtc">
            {{valueText}}
          </template>
          <template v-else-if="isEtcExpression && isEtc">
            <q-chip
              outline square 
              clickable
              color="deep-purple"
              text-color="white" 
              icon="edit"
              class="q-ma-none customchipdept"
              @click.stop="clickEtc">
              기타
            </q-chip>
            <input 
              ref="location-single-etc-input"
              class="location-etc-input"
              v-model="data[etcInput]"
              @click.stop />
          </template>
        </template>
        <template v-else>
          <q-chip
            v-for="(location, index) in rejectEtcLocations"
            :key="index"
            outline square 
            :removable="!disabled&&editable"
            :color="isMapShowing ? (location.sopMapLocationId ? 'blue' : 'red') : 'orange'"
            text-color="white"
            class="q-ma-none customchipdept"
            @remove="remove(location)">
            {{location.locationName}}
            <template v-if="isMapShowing">
              <q-tooltip content-class="bg-amber text-black shadow-4">
                {{location.sopMapLocationId ? '배치도면 연결 O' : '배치도면 연결 X'}}
              </q-tooltip>
            </template>
          </q-chip>
          <template v-if="etcLocation && isEtcExpression">
            <q-chip
              v-show="!isEtcWrite" 
              outline square 
              clickable
              :removable="!disabled&&editable"
              color="deep-purple"
              text-color="white" 
              icon="edit"
              class="q-ma-none customchipdept"
              @click.stop="clickEtc"
              @remove="remove(etcLocation)">
              (기타) {{data[etcInput]}} 
            </q-chip>
            <input 
              v-show="isEtcWrite" 
              ref="location-etc-input"
              class="location-etc-input"
              v-model="data[etcInput]"
              @click.stop
              @blur="etcBlur"
              v-on:keyup.enter="etcBlur" />
          </template>
        </template>
      </template>

      <template v-slot:append>
        <q-icon v-if="!disabled&&editable" name="search" @click="search" class="cursor-pointer"></q-icon>
        <q-icon v-if="!disabled&&editable" name="close" @click="reset" class="cursor-pointer"></q-icon>
      </template>
    </q-field>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'c-location',
  props: {
    name: {
      type: String,
      default: 'field',
    },
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: { // 사용자의 id를 받아 처리
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    nodateLabel: {
      type: String,
      default: '미선택',
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'edit',
    },
    multiple: {
      type: String,
      default: 'single',
    },
    placeholder: {
      type: String,
      default: '',
    },
    // 필수 입력 여부
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    plantCd: {
      type: String,
      default: '',
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    isMapShowing: {
      type: Boolean,
      default: false,
    },
    etcFlag: {
      type: String,
      default: 'N',
    },
    data: {
      type: Object,
    },
    etcInput: {
      type: String,
      default: 'locationEtc',
    },
  },
  data() {
    return {
      vValue: null,
      valueText: '',
      locations: [],
      isEtcWrite: false,
      pathUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '40%',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    etcLocation() {
      let etc = this.$_.find(this.locations, { mdmLocationId: 'ETC' });
      return etc ? etc : null
    },
    isEtcExpression() {
      return this.data && this.etcInput
    },
    isEtc() {
      /**
       * single인 경우만 해당
       */
      return !this.isMultiple && this.value === 'ETC'
    },
    rejectEtcLocations() {
      let items = this.locations
      if (this.isEtcExpression) {
        items = this.$_.reject(this.locations, { mdmLocationId: 'ETC' });
      }
      return items;
    }
  },
  watch: {
    // todo : 부모의 v-model 변경을 감시
    value: {
      handler: function () {
        if (this.value) {
          this.vValue = this.value;
          this.getLocationName(this.value);
        } else {
          this.$emit('input', null);
          this.locations = [];
          this.valueText = '';
        }
      },
      deep: true,
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeMount() {
    this.vValue = this.value;
    this.pathUrl = selectConfig.mdm.location.path.url;
    if (this.value) {
      this.getLocationName(this.value);
    } else {
      this.valueText = '';
    }
  },
  mounted() {
    if (!this.disabled) {
      this.$refs['customLocation'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  beforeDestroy() {
    if (!this.disabled) {
      this.$refs['customLocation'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  /* methods */
  methods: {
    getLocationName(_id) {
      this.$http.url = this.pathUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        mdmLocationIds: this.isMultiple ? _id : null,
        mdmLocationId: !this.isMultiple ? _id : null,
      };
      this.$http.request((_result) => {
        if (_result.data && _result.data.length > 0) {
          if (!this.isMultiple) {
            this.valueText = _result.data[0].locationName;
            if (_result.data[0].mdmLocationId === 'ETC') {
              setTimeout(() => {
                this.$refs['location-single-etc-input'].focus();
              }, 300);
            }
          } else {
            if (this.locations && this.locations.length > 0) {
              this.$_.forEach(this.locations, location => {
                this.$set(location, 'locationName', this.$_.find(_result.data, { mdmLocationId: location.mdmLocationId }).locationName)
              })
            } else {
              this.locations = _result.data
            }
          }
        }
      },);
    },
    search() {
      this.popupOptions.title = '장소 검색';
      this.popupOptions.param = {
        type: this.isMultiple ? 'multiple' : 'single',
        plantCd: this.plantCd,
        isMapShowing: this.isMapShowing,
        etcFlag: this.etcFlag,
      };
      this.popupOptions.target = () => import(`${'@/pages/mdm/lim/locationPop.vue'}`);
      this.popupOptions.width = '100%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        // this.locations = data;
        if (!this.isMultiple) {
          if (this.isEtcExpression && this.isEtc) {
            this.$set(this.data, this.etcInput, '')
          }
          this.$emit('input', data[0].mdmLocationId);
          this.$emit('dataChange', data[0], 'ADD');
        } else {
          this.$_.forEach(data, location => {
            if (this.$_.findIndex(this.locations, { mdmLocationId: location.mdmLocationId }) === -1) {
              this.locations.push(location)
            }
          })
          if (this.isEtcExpression && this.$_.findIndex(this.locations, { mdmLocationId: 'ETC' }) > -1) {
            this.$set(this.data, this.etcInput, '')
          }
          this.$emit('input', this.$_.map(this.locations, 'mdmLocationId').join(','));
          this.$emit('dataChange', this.locations, 'ADD');
        }
      }
    },
    reset() {
      this.valueText = '';
      this.$emit('input', null);
      this.$emit('dataChange', null, 'DELETE');
    },
    clickEtc() {
      this.isEtcWrite = true;
      setTimeout(() => {
        this.$refs['location-etc-input'].focus()
      }, 300);
    },
    etcBlur() {
      this.isEtcWrite = false;
    },
    remove(location) {
      // 문자열로 들어온 경우 ** 배열로 들어온 경우는 고려하지 않음
      this.locations = this.$_.reject(this.locations, { mdmLocationId: location.mdmLocationId });

      this.$emit('input', this.$_.map(this.locations, 'mdmLocationId').join(','));
      this.$emit('dataChange', this.locations, 'DELETE');
    },
  },
};
</script>
<!-- <style lang="sass">
.customLocation
  padding-bottom: 20px !important
  .q-field__native.row
    padding-left: 3px
    font-size: 0.95em
    padding-top: 4px !important
  .q-field__native
    padding-bottom: 6px !important
    cursor: pointer
  .q-field__label
    margin-left: 5px
  .material-icons
    font-size: 0.8rem
  .dateCloseIcon
    font-size: 0.8rem !important
    display: none
  .q-field__native.row
    padding-bottom: 0px !important
.customLocation:hover
  .dateCloseIcon
    display: block
.customLocation.q-field--dense .q-field__control, .customLocation.q-field--dense .q-field__marginal
  height: min-content !important
  min-height: 30px !important
.customLocation.q-field--filled .q-field__control
  border: 1px solid rgba(0,0,0,0.15) !important
  padding: 0px 8px 0px 2px

.location-etc-input
  border-width: 0px
  height: 24px
  line-height: 18px
  margin-top: -3px
  width: 60% !important
.location-etc-input:focus-visible 
  outline: #673ab7 auto 0px !important

</style> -->