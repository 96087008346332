var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "td-input-column" }, [
    !_vm.isShow
      ? _c("div", { staticClass: "pad-txt-label" }, [
          _c("div", {
            class: [
              _vm.disabled ? "" : "table-td-textlabel",
              _vm.colorClass ? _vm.colorClass : "",
            ],
            attrs: { tabindex: "0" },
            domProps: { innerHTML: _vm._s(_vm.viewText) },
            on: {
              focus: function ($event) {
                return _vm.textfocus()
              },
              click: function ($event) {
                return _vm.textfocus()
              },
            },
          }),
        ])
      : _vm._e(),
    _vm.editable && _vm.isShow
      ? _c(
          "div",
          [
            _c("q-input", {
              ref: "input-column",
              staticClass: "table-td-text-grid",
              attrs: {
                type: "textarea",
                outlined: "",
                autofocus: "",
                prefix: _vm.prefix ? _vm.prefix : void 0,
                suffix: _vm.suffix ? _vm.suffix : void 0,
                autogrow: true,
                disable: _vm.disabled,
              },
              on: {
                input: _vm.datachange,
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  if (
                    $event.ctrlKey ||
                    $event.shiftKey ||
                    $event.altKey ||
                    $event.metaKey
                  )
                    return null
                  $event.preventDefault()
                },
                blur: function ($event) {
                  return _vm.textfocusout()
                },
              },
              model: {
                value: _vm.props.row[_vm.col.name],
                callback: function ($$v) {
                  _vm.$set(_vm.props.row, _vm.col.name, $$v)
                },
                expression: "props.row[col.name]",
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }